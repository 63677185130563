import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ImageGridBlock = ({ data }) => {
  return (
    <section className="cb-image-grid">
      <div className="container-lg">
        {data.images.map((i, index) => (
          <div
            className="cb-image-grid__item"
            key={`${index}__${i.id}`}
            data-sal="fade"
            data-sal-delay={`${index * 100}`}
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            <GatsbyImage image={getImage(i.gatsbyImageData)} alt={i.title} />
          </div>
        ))}
      </div>
    </section>
  )
}

export default ImageGridBlock
