import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ResolveContentBlocks from "../components/content-blocks/ResolveContentBlocks"

const ProjectTemplate = ({ data }) => {
  return (
    <Layout data={{ headerType: "dark" }}>
      <SEO
        title={`${data.contentfulProjects.seoMetaTitle} | Projects`}
        description={
          data.contentfulProjects.seoMetaDescription.seoMetaDescription
        }
      />
      <section className="project-hero">
        <div className="container-lg">
          <div className="project-hero__text">
            <div
              className="project-hero__text-title"
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-easing="ease"
              data-sal-duration="800"
            >
              {data.contentfulProjects.projectname}
            </div>
            <h1
              className="project-hero__text-heading"
              data-sal="slide-up"
              data-sal-delay="250"
              data-sal-easing="ease"
              data-sal-duration="800"
            >
              {data.contentfulProjects.projectDescription.projectDescription}
            </h1>
            <div className="project-hero__text-plan">
              {data.contentfulProjects.technology.map((t, i) => (
                <span
                  key={i}
                  data-sal="slide-up"
                  data-sal-delay={`${100 + i * 100}`}
                  data-sal-easing="ease"
                  data-sal-duration="800"
                >
                  {t}
                </span>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="project-banner">
        <GatsbyImage
          data-sal="fade"
          data-sal-delay="100"
          data-sal-easing="ease"
          data-sal-duration="900"
          image={getImage(
            data.contentfulProjects.projectBanner.gatsbyImageData
          )}
          alt={data.contentfulProjects.projectBanner.title}
        />
      </section>
      <div className="project-body">
        <ResolveContentBlocks blocks={data.contentfulProjects.projectContent} />
        {data.contentfulProjects.shopUrl && (
          <div className="project-link container-lg">
            <a
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-easing="ease"
              data-sal-duration="800"
              href={data.contentfulProjects.shopUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Website
            </a>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default ProjectTemplate

export const pageQuery = graphql`
  query($id: String) {
    contentfulProjects(id: { eq: $id }) {
      id
      client
      projectname
      projectBanner {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        title
      }
      projectThumbnail {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        title
      }
      technology
      seoMetaTitle
      seoMetaDescription {
        seoMetaDescription
      }
      projectDescription {
        projectDescription
      }
      shopUrl
      projectContent {
        __typename
        ... on Node {
          ... on ContentfulContentBlockBanner {
            id
            bannerPosition
            bannerImage {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              id
              title
            }
          }
          ... on ContentfulContentBlockScrollContainer {
            id
            containerContent {
              raw
            }
            containerImage {
              id
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              title
            }
            sectionTheme
            sectionBackground
          }
          ... on ContentfulContentBlockTextContent {
            id
            layoutAlignment
            sectionTitle
            sectionHeading
            sectionContent {
              raw
            }
          }
          ... on ContentfulContentBlockBannerTextIconBox {
            id
            sectionTitle
            sectionHeading
            sectionDescription {
              sectionDescription
            }
            sectionBanner {
              title
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
            sectionIconBoxes {
              id
              title
              description {
                description
              }
              icon {
                title
                gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              }
            }
          }
          ... on ContentfulContentBlockImageGrid {
            id
            images {
              id
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              title
            }
          }
          ... on ContentfulContentBlockClientTestimonial {
            id
            clientName
            testimonialContent {
              testimonialContent
            }
          }
          ... on ContentfulContentBlockIconBox {
            id
            icon {
              id
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              title
            }
          }
          ... on ContentfulContentBlockTextImageContent {
            id
            sectionTitle
            sectionHeading
            sectionContent {
              raw
            }
            sectionImage {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              title
            }
            layoutAlignment
          }
          ... on ContentfulContentBlockProjectCredits {
            id
            sectionTitle
            backgroundColor
            theme
            credits {
              id
              creditTitle
              creditNames
            }
          }
        }
      }
    }
  }
`
