import React from "react"
import BannerBlock from "./BannerBlock"
import TextBlock from "./TextBlock"
import StickyScrollBlock from "./StickyScrollBlock"
import BannerTextIconBlock from "./BannerTextIconBlock"
import ClientTestimonial from "./ClientTestimonial"
import ImageGridBlock from "./ImageGridBlock"
import TextImageBlock from "./TextImageBlock"
import ProjectCreditsBlock from "./ProjectCreditsBlock"

const ComponentMap = {
  ContentfulContentBlockTextContent: TextBlock,
  ContentfulContentBlockBanner: BannerBlock,
  ContentfulContentBlockScrollContainer: StickyScrollBlock,
  ContentfulContentBlockBannerTextIconBox: BannerTextIconBlock,
  ContentfulContentBlockClientTestimonial: ClientTestimonial,
  ContentfulContentBlockImageGrid: ImageGridBlock,
  ContentfulContentBlockTextImageContent: TextImageBlock,
  ContentfulContentBlockProjectCredits: ProjectCreditsBlock,
}

const ResolveContentBlocks = ({ blocks }) => {
  if (blocks !== null) {
    return blocks.map((item, index) => {
      const DynamicComponent = ComponentMap[item.__typename]
      if (DynamicComponent !== undefined) {
        return <DynamicComponent key={item.id} data={item} />
      } else {
        return <></>
      }
    })
  } else {
    return <></>
  }
}

export default ResolveContentBlocks
