import React from "react"

const ClientTestimonial = ({ data }) => {
  return (
    <div className="container-lg">
      <div className="testimonial">
        <div className="testimonial__title">
          <span></span>
          <span
            className="cb-section-title"
            data-sal="fade"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            TESTIMONIAL
          </span>
        </div>
        <div className="testimonial__row">
          <div
            data-sal="fade"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="800"
            className="testimonial__qoutes"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 195.48 146.61">
              <defs></defs>
              <g transform="translate(0 -4)">
                <g transform="translate(0 4)">
                  <path
                    fill="#030104;"
                    d="M0,4V150.61L73.3,77.3V4Z"
                    transform="translate(0 -4)"
                  />
                  <path
                    fill="#030104;"
                    d="M20,4V150.61L93.3,77.3V4Z"
                    transform="translate(102.175 -4)"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="testimonial__content">
            <div
              className="testimonial__content-text"
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-easing="ease"
              data-sal-duration="800"
            >
              {data.testimonialContent.testimonialContent}
            </div>
            <div
              className="testimonial__content-client"
              data-sal="slide-up"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="800"
            >
              {data.clientName}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientTestimonial
