import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useResolveRichText from "../../hooks/useResolveRichText"

const StickyScrollBlock = ({ data }) => {
  const formattedHTML = useResolveRichText(data.containerContent)
  return (
    <section 
      className={`cb-sticky-scroll__wrapper ${data.sectionTheme}`}
      style={{
        backgroundColor: data.sectionBackground.includes("#")
          ? `${data.sectionBackground}`
          : `#${data.sectionBackground}`,
      }}
    >
      <div className="cb-sticky-scroll__container container-lg">
        <div className="cb-sticky-scroll__col">
          <div className="cb-sticky-scroll__col-content">{formattedHTML}</div>
        </div>
        <div className="cb-sticky-scroll__col">
          <div className="cb-sticky-scroll__col-scroll-img">
            <GatsbyImage
              data-sal="fade"
              data-sal-delay="100"
              data-sal-easing="ease"
              data-sal-duration="800"
              image={data.containerImage.gatsbyImageData}
              alt={data.containerImage.title}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default StickyScrollBlock
