import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const IconBox = ({ data }) => {
  return (
    <div className="icon-box">
      <div
        className="icon-box__img"
        data-sal="fade"
        data-sal-delay="150"
        data-sal-easing="ease"
        data-sal-duration="800"
      >
        <GatsbyImage
          image={getImage(data.icon.gatsbyImageData)}
          alt={data.icon.title}
        />
      </div>
      <div className="icon-box__content">
        <h3
          className="icon-box__content-title"
          data-sal="slide-up"
          data-sal-delay="250"
          data-sal-easing="ease"
          data-sal-duration="800"
        >
          {data.title}
        </h3>
        <p
          className="icon-box__content-description"
          data-sal="slide-up"
          data-sal-delay="350"
          data-sal-easing="ease"
          data-sal-duration="800"
        >
          {data.description.description}
        </p>
      </div>
    </div>
  )
}

export default IconBox
