import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import useResolveRichText from "../../hooks/useResolveRichText"

const TextImageBlock = ({ data }) => {
  const formattedHTML = useResolveRichText(data.sectionContent)
  return (
    <section className="cb-text-image">
      <div className="container-lg">
        <div className={`cb-text-image__row ${data.layoutAlignment}`}>
          <div className="cb-text-image__row-col">
            <span
              className="cb-section-title"
              data-sal="slide-up"
              data-sal-delay="150"
              data-sal-easing="ease"
              data-sal-duration="800"
            >
              {data.sectionTitle}
            </span>
            <div
              className="h1-text"
              data-sal="slide-up"
              data-sal-delay="250"
              data-sal-easing="ease"
              data-sal-duration="800"
            >
              {data.sectionHeading}
            </div>
            {data.layoutAlignment === "Title-Content-Image" && (
              <div className="cb-text-image__content">{formattedHTML}</div>
            )}
            {data.layoutAlignment === "Title-Image-Content" && (
              <div
                data-sal="fade"
                data-sal-delay="180"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <GatsbyImage
                  image={getImage(data.sectionImage.gatsbyImageData)}
                  alt={data.sectionImage.title}
                />
              </div>
            )}
          </div>
          <div className="cb-text-image__row-col">
            {data.layoutAlignment === "Title-Content-Image" && (
              <div
                data-sal="fade"
                data-sal-delay="180"
                data-sal-easing="ease"
                data-sal-duration="800"
              >
                <GatsbyImage
                  image={getImage(data.sectionImage.gatsbyImageData)}
                  alt={data.sectionImage.title}
                />
              </div>
            )}
            {data.layoutAlignment === "Title-Image-Content" && (
              <div className="cb-text-image__content">{formattedHTML}</div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextImageBlock
