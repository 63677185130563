import React from "react"

const ProjectCreditsBlock = ({ data }) => {
  return (
    <section className="cb-project-credits">
      <div className="container-lg">
        <div
          className={`cb-project-credits__wrapper ${data.theme}`}
          style={{
            backgroundColor: data.backgroundColor.includes("#")
              ? `${data.backgroundColor}`
              : `#${data.backgroundColor}`,
          }}
        >
          <div
            className="cb-project-credits__title"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            {data.sectionTitle}
          </div>
          <div
            className={
              data.credits.length > 3
                ? "cb-project-credits__row span-1"
                : "cb-project-credits__row"
            }
          >
            {data.credits.map((c, index) => (
              <div className="cb-project-credits__row-item" key={c.id}>
                <div
                  className="credit-heading"
                  data-sal="slide-up"
                  data-sal-delay={`${index * 100}`}
                  data-sal-easing="ease"
                  data-sal-duration="800"
                >
                  {c.creditTitle}
                </div>
                <div className="credit-names">
                  {c.creditNames.map((n, index) => (
                    <li
                      key={index}
                      data-sal="slide-up"
                      data-sal-delay={`${index * 100}`}
                      data-sal-easing="ease"
                      data-sal-duration="800"
                    >
                      {n}
                    </li>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectCreditsBlock
