import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const useResolveRichText = rawHTML => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { gatsbyImageData, title, file } = node.data.target

        if (file.contentType === "video/mp4")
          return (
            <video
              controls
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-easing="ease"
              data-sal-duration="800"
            >
              <source src={file.url} type={file.contentType} />
            </video>
          )
// 
        // if (file.contentType === "application/json")
        //   return (
        //     <div className="lottie-player-wrap">
        //       <Lottie
        //         className="lottie-player"
        //         options={{
        //           loop: true,
        //           autoplay: true,
        //           path: file.url,
        //           rendererSettings: {
        //             preserveAspectRatio: "xMidYMid slice",
        //           },
        //         }}
        //       />
        //     </div>
        //   )
// 
        return (
          <GatsbyImage
            className="blog-img"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="800"
            image={getImage(gatsbyImageData)}
            alt={title}
          />
        )
      },

      [BLOCKS.HEADING_1]: (node, children) => {
        return (
          <div
            className="h1-text"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            {children}
          </div>
        )
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return (
          <h2
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            {children}
          </h2>
        )
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return (
          <h3
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            {children}
          </h3>
        )
      },
      [BLOCKS.HEADING_4]: (node, children) => {
        return (
          <h4
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            {children}
          </h4>
        )
      },
      [BLOCKS.HEADING_5]: (node, children) => {
        return (
          <h5
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            {children}
          </h5>
        )
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <p
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            {children}
          </p>
        )
      },
      [BLOCKS.UL_LIST]: (node, children) => {
        return (
          <ul
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            {children}
          </ul>
        )
      },
      [BLOCKS.OL_LIST]: (node, children) => {
        return (
          <ol
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            {children}
          </ol>
        )
      },
    },
  }
  return renderRichText(rawHTML, options)
}

export default useResolveRichText
