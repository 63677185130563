import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import IconBox from "./IconBox"

const BannerTextIconBlock = ({ data }) => {
  return (
    <section className="banner-text-icon">
      <div className="container-lg">
        <span
          className="cb-section-title"
          data-sal="slide-up"
          data-sal-delay="100"
          data-sal-easing="ease"
          data-sal-duration="800"
        >
          {data.sectionTitle}
        </span>
        <div className="banner-text-icon__banner">
          <GatsbyImage
            data-sal="fade"
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-duration="800"
            image={getImage(data.sectionBanner.gatsbyImageData)}
            alt={data.sectionBanner.title}
          />
        </div>
        <div className="banner-text-icon__content">
          <h2
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            {data.sectionHeading}
          </h2>
          <p
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            {data.sectionDescription.sectionDescription}
          </p>
        </div>
        <div className="banner-text-icon__row">
          {data.sectionIconBoxes.map(i => (
            <IconBox key={i.id} data={i} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default BannerTextIconBlock
