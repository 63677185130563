import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BannerBlock = ({ data }) => {
  return (
    <section>
      <div
        className={
          data.bannerPosition === "Centered"
            ? `container-lg cb-image-banner ${data.bannerPosition}`
            : `cb-image-banner ${data.bannerPosition}`
        }
      >
        <GatsbyImage
          image={getImage(data.bannerImage.gatsbyImageData)}
          alt={data.bannerImage.title}
        />
      </div>
    </section>
  )
}

export default BannerBlock
