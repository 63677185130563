import React from "react"
import useResolveRichText from "../../hooks/useResolveRichText"

const TextBlock = ({ data }) => {
  const formattedHTML = useResolveRichText(data.sectionContent)
  return (
    <section className="cb-text-block">
      <div
        className={`cb-text-block__container container-lg ${data.layoutAlignment}`}
      >
        <div className="cb-text-block__container-text">
          <span
            className="cb-section-title"
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            {data.sectionTitle}
          </span>
          <h2
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-duration="800"
          >
            {data.sectionHeading}
          </h2>
        </div>
        <div className="rich-content">{formattedHTML}</div>
      </div>
    </section>
  )
}

export default TextBlock
